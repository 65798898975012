import React from "react";
import {
  SliceConfig,
  Grid,
  PortableTextRenderer,
  IntersectionAnimation
} from "~components";
import { ISliceConfig } from "~schemas";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  removeTopBorder?: boolean;
  noAnimation?: boolean;
  data: {
    _rawLeftColumn?: any;
    _rawRightColumn?: any;
    sliceConfig?: ISliceConfig;
  };
}

const TextArticle = ({
  data: { _rawLeftColumn, _rawRightColumn, sliceConfig },
  noAnimation,
  removeTopBorder
}: IProps) => (
  <section
    className={cn(styles.container, {
      [styles.removeTopBorder]: removeTopBorder
    })}
  >
    <SliceConfig config={sliceConfig}>
      <Grid>
        {_rawLeftColumn && (
          <div className={styles.leftColumn}>
            <IntersectionAnimation delay={150} trigger={noAnimation}>
              <PortableTextRenderer rawText={_rawLeftColumn} />
            </IntersectionAnimation>
          </div>
        )}
        {_rawRightColumn && (
          <div className={styles.rightColumn}>
            <IntersectionAnimation delay={150} trigger={noAnimation}>
              <PortableTextRenderer rawText={_rawRightColumn} />
            </IntersectionAnimation>
          </div>
        )}
      </Grid>
    </SliceConfig>
  </section>
);

export default TextArticle;
