import React from "react";
import COLORS from "~data/colors.json";
import { SliceConfig, IntersectionAnimation } from "~components";
import { useInView } from "react-intersection-observer";
import { hexToRGB } from "~utils";
import { ISliceConfig } from "~schemas";
import * as styles from "./styles.module.scss";

interface IProps {
  data: {
    category: string;
    sliceConfig: ISliceConfig;
  };
}

const ColorGrid = ({ data: { category, sliceConfig } }: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`
  });

  return (
    <section>
      <SliceConfig config={sliceConfig}>
        <IntersectionAnimation>
          <ul className={styles.grid} ref={ref}>
            {Object.keys(COLORS).map((colorKey, i) => {
              const color = COLORS[colorKey];
              const { swatch, category: colorCategory, hex, display } = color;

              if (colorCategory !== category) return null;

              const rgb = hexToRGB(hex);

              const fontColor = display === `dark` ? `white` : `black`;

              return (
                <IntersectionAnimation
                  trigger={inView}
                  key={i}
                  animation="fadeGrow"
                  delay={i * 50}
                >
                  <li
                    className={styles.gridItem}
                    style={{ background: hex, color: fontColor }}
                  >
                    <div className={styles.gridItemContent}>
                      <p className="b2">{swatch}</p>

                      <div>
                        <p className="b2">{hex}</p>
                        <p className="b2">{`R ${rgb?.r || `0`}  G ${
                          rgb?.g || `0`
                        }  B ${rgb?.g || `0`}`}</p>
                      </div>
                    </div>
                  </li>
                </IntersectionAnimation>
              );
            })}
          </ul>
        </IntersectionAnimation>
      </SliceConfig>
    </section>
  );
};

export default ColorGrid;
